const config = {
    firebase: {
        apiKey: "AIzaSyBq_W54sqvcjv9YriYqIaIPKfYDmL0QOwM",
        authDomain: "houtzaagkunst2.firebaseapp.com",
        projectId: "houtzaagkunst2",
        storageBucket: "houtzaagkunst2.appspot.com",
        messagingSenderId: "169167297142",
        appId: "1:169167297142:web:9eb167ac58da559c891d57",
        measurementId: "G-EESS7JR4V0"
    }
}

export default config;