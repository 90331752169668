import React, {FC, useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
// firebase
import {auth} from "./config/firebase";
// import firebase from "firebase/app";
// import firebase from "firebase/compat";
// import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, getDocs, Firestore} from 'firebase/firestore/lite';
//pages
import Gallery from "./page/Gallery";
import ArtworkShow from './page/ArtworkShow';
import About from './page/About';

//MUI
import {CircularProgress} from "@material-ui/core";

import Navbar from "./component/Navbar";
import CSS from "csstype";

const container: CSS.Properties = {
    margin: "80px auto 0 auto",
    maxWidth: "1200px"
};

const center: CSS.Properties = {
    position: "absolute",
    left: "50%",
    top: "50%"
};

export interface IApplicationProps {
}

/***
 * Firebase collection: Home -> contains kind home, with field text
 */

const App: FC<IApplicationProps> = props => {
    const [loading, setLoading] = useState<boolean>(true);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                console.info('User detected.');
                setAuthenticated(true)
            } else {
                console.info('No user detected');
                setAuthenticated(false)
            }

            setLoading(false);
        })
    }, []);

    if (loading)
        return <CircularProgress style={center}/>


    // export default interface IRoute {
    //     path: string;
    //     exact: boolean;
    //     component: any;
    //     name: string;
    //     protected: boolean;
    // }

    return (
        <Router>
            <Navbar authenticated={authenticated}/>
            <div style={container}>
                <Switch>
                    <Route exact path='/' render={() => <Gallery/>}/>
                    <Route exact path='/about' component={About}/>
                    <Route exact path='/artwork/:title' component={ArtworkShow}/>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
