import React from 'react';
import {Link} from "react-router-dom";
//UI
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import CSS from "csstype";
import dayjs from "dayjs";

interface Artwork {
    artwork: {
        artId: string,
        imgCount?: number
        title: string,
        username?: string
        createdAt: string,
        likeCount?: number,
        description: string,
        commentCount?: number,
        url: string,
    }
}

const cardMedia: CSS.Properties = {
    minWidth: "200px",
    minHeight: "500px",
    objectFit: 'cover',
};

const card: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: "20px",
};

const cardContent: CSS.Properties = {
    padding: "25px",
};

const titleColor: CSS.Properties = {
    color: "#1976d2",
}


export default function ArtworkCard(artwork: Artwork) {
    return (
        <Grid item md={4} sm={6} xs={12}>
            <Card style={card}>
                <CardMedia image={artwork.artwork.url} title={artwork.artwork.title} style={cardMedia}/>
                <CardContent style={cardContent}>
                    <Typography variant="h5" component={Link} to={`/artwork/${artwork.artwork.title}`}
                                color="primary" style={titleColor}>{artwork.artwork.title}</Typography>
                    <Typography variant="body2"
                                color={"textSecondary"}>{dayjs(artwork.artwork.createdAt).format('DD-MM-YYYY')}</Typography>
                    <Typography variant="body1">{artwork.artwork.description}</Typography>
                    {/*<LikeButton id={artwork.artwork.artId}/>*/}
                    {/*<span>{artwork.artwork.likeCount} Likes</span>*/}
                    {/*<MyButton tip='Comment count'>*/}
                    {/*    <ChatIcon color="primary"/>*/}
                    {/*</MyButton>*/}
                    {/*<span>{artwork.artwork.commentCount} Comments</span>*/}
                    {/*<ArtworkDialog artwork={artwork.artwork}/>*/}
                </CardContent>
            </Card>
        </Grid>
    );
}