import React, {useEffect, useRef, useState} from 'react';

type Props = {
    onBottomHit: () => void;
    isLoading: boolean;
    hasMoreData: boolean;
    loadOnMount: boolean;
};

function isBottom(ref: React.RefObject<HTMLDivElement>) {
    if (!ref.current) {
        return false;
    }

    return ref.current.getBoundingClientRect().bottom <= window.innerHeight;
}

const InfiniteScroll: React.FC<Props> = ({
                                             onBottomHit,
                                             isLoading,
                                             hasMoreData,
                                             loadOnMount,
                                             children,
                                         }) => {
    const [initialLoad, setInitialLoad] = useState(true);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (loadOnMount && initialLoad) {
            onBottomHit();
            setInitialLoad(false);
        }
    }, [onBottomHit, loadOnMount, initialLoad]);

    /*** This methos is called every time you scroll. it checks some variables,
     * loading en hasmore data and the function for th bottom of the page
     */
    useEffect(() => {
        // debugger;
        const onScroll = () => {
            if (!isLoading && hasMoreData && isBottom(contentRef)) {
                onBottomHit();
            }
        };
        document.addEventListener('scroll', onScroll);
        return () => document.removeEventListener('scroll', onScroll);
    }, [onBottomHit, isLoading, hasMoreData]);

    return <div ref={contentRef}>{children}</div>;
};


export default InfiniteScroll

