import React from 'react';
import CSS from "csstype";
import Grid from "@material-ui/core/Grid";

interface ImageCard {
        url: string, title: string, key: number
}

const image: CSS.Properties = {
    marginRight: "10px",
    marginTop: "10px",
    maxWidth: "400px",
    objectFit: 'contain',
    height: "400px"
};


const ImageCard = (imageCard: ImageCard) => {
// export default function ImageCard(artwork: Artwork) {
    return (
        <Grid item md={4} sm={6} xs={12}>
            <img src={imageCard.url} alt={imageCard.title} style={image}/>
        </Grid>
    );
}


export default ImageCard