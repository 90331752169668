import React, {FC} from 'react';
// @ts-ignore
import {Link, useHistory} from "react-router-dom";
//MUI
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
//Style
import CSS from 'csstype';
import {auth} from "../config/firebase";

const toolbar: CSS.Properties = {
    margin: "auto",
    color: "#ffffff"
};

const appBar: CSS.Properties = {
    backgroundColor: "#1976d2"
}

const button: CSS.Properties = {
    marginTop: "10px",
    position: 'relative'
};

export interface INavbarProps {
    authenticated: boolean
}

const logout = () => {
    auth.signOut()
        .then(() => console.log("Log out"))
        .catch(error => console.error(error));
}

const Navbar: FC<INavbarProps> = props => {
    return (
        <AppBar style={appBar}>
            <Toolbar style={toolbar}>
                <div>
                    <Button style={button} color='inherit' component={Link} to='/'>Galerie</Button>
                    <Button style={button} color='inherit' component={Link} to='/about'>Over</Button>
                </div>
            </Toolbar>
        </AppBar>

    );
}

export default Navbar;
