import React from 'react';
import {useParams} from 'react-router-dom';
import ImageCard from '../component/ImageCard'
import CSS from "csstype";
import Grid from "@material-ui/core/Grid";

const containerGallery: CSS.Properties = {
    textAlign: 'center'
};

const ArtworkShow = () => {
    const {title}: { title: string } = useParams();
    // const titles = ["Meerkoet", "Vrouwen torso"]
    const artworkShow = [{
        name: "Meerkoet",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FMeerkoet%2FIMG_5331.JPG?alt=media&token=3ff22b8e-ef50-45b0-bb69-716405655e9f',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FMeerkoet%2FIMG_5332.JPG?alt=media&token=37a60574-f886-48d2-a5fc-f7085f0a7081',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FMeerkoet%2FIMG_5333.JPG?alt=media&token=503a3717-47ab-410e-89de-9decdba19871',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FMeerkoet%2FIMG_5334.JPG?alt=media&token=08cca6d4-2352-48c9-90e5-124b100961b0']
    }, {
        name: "Arend",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FArend%2FArend.jpg?alt=media&token=c7c6f3b6-cf4f-4e04-9947-77dfea6a6fc3']
    }, {
        name: "Wildzwijn",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FWildzwijn%2FWildzwijn.jpg?alt=media&token=a0d16a2c-4ef8-4e51-a34a-328aee03e686']
    }, {
        name: "Varken",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVarken%2FVarken.jpg?alt=media&token=37cb4726-0209-4b39-8772-8c7564d70a7c']
    }, {
        name: "Nijlpaard",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FNijlpaard%2FNijlpaard.jpg?alt=media&token=bd0036b9-ac83-4b28-a610-1dfe884d7ef9']
    }, {
        name: "Mond",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FMond%2FMond.jpg?alt=media&token=5a7b35c0-5cde-4fa6-9aad-e87a56697254']
    }, {
        name: "Kunst",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKunst%2FKunst.jpg?alt=media&token=a31c605b-e66f-4920-bd31-d4287b31a376']
    }, {
        name: "Hand",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FHand%2FHand.jpg?alt=media&token=79e1562e-c9b5-4ade-8155-bf41cbf08f16']
    }, {
        name: "Vogels",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVogels%2FVogel%20(2).jpg?alt=media&token=494eca7e-fd28-4658-bbf1-fae27ce373ab',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVogels%2FVogel%20(1).jpg?alt=media&token=915543d7-d7c7-4245-8865-29787fba65af',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVogels%2FArend.jpg?alt=media&token=76a422af-b8ce-4755-8e21-c2969f7676fd',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVogels%2FVogel.jpg?alt=media&token=689b75c0-0dc6-4160-a5ae-463b42fd6ce0']
    }, {
        name: "Verwaande vogel",
        urls: [
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVerwaande%20vogel%2FVerwaande%20vogel%20(2).jpg?alt=media&token=534c26af-9499-45cf-abe2-8de98e3b2ae1',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVerwaande%20vogel%2FVerwaande%20vogel%20(3).jpg?alt=media&token=85247163-1bf8-426e-8275-933430584488',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVerwaande%20vogel%2FVerwaande%20vogel%20(1).jpg?alt=media&token=6280374e-bcbc-4a13-a752-773fd1bcf469',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVerwaande%20vogel%2FVerwaande%20vogel.jpg?alt=media&token=4233478e-6362-41f7-b0df-c86996f249a4']
    }, {
        name: "Uil",
        urls: [
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FUil%2FIMG_5536.JPG?alt=media&token=d14c624d-e9fe-4dc0-8555-c20ccfa51399',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FUil%2FIMG_5537.JPG?alt=media&token=59ee09ed-a405-488e-a686-135ac255d741',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FUil%2FIMG_5539.JPG?alt=media&token=b53b174f-b8a1-42f1-a090-b75a2b339c8a',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FUil%2FIMG_5542.JPG?alt=media&token=14f997ad-bf73-49cf-9216-131cf7e9aaa7',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FUil%2FIMG_5534.JPG?alt=media&token=f373c4fb-68ea-4381-99b2-dde1b4b22c05']
    }, {
        name: "Totempaal",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FTotempaal%2FTotempaal%20(1).jpg?alt=media&token=3fe7d40f-9429-44f6-a539-76b9f2f4382c',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FTotempaal%2FTotempaal%20(2).jpg?alt=media&token=e95e3a61-a21e-4a46-9814-739531539562',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FTotempaal%2FTotempaal%20(3).jpg?alt=media&token=05002863-f634-4407-ab77-aae986e9fb69',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FTotempaal%2FTotempaal%20(4).jpg?alt=media&token=2668deac-5187-481b-ae5f-458cd021e166',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FTotempaal%2FTotempaal.jpg?alt=media&token=e949d1ca-251c-465d-9a4c-b17fd0ff6cdd',]
    }, {
        name: "Sieraad",
        urls: [
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FSieraad%2FIMG_4368.JPG?alt=media&token=60ec866d-a2fe-4197-9118-c2401151749c',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FSieraad%2FIMG_4361.JPG?alt=media&token=5708d615-62c8-42b5-adf6-a9d892d223b9',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FSieraad%2FIMG_4358.JPG?alt=media&token=6e73b4dc-9c71-4fa0-bb1d-22b47c4f12d7',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FSieraad%2FEricDerksen.JPG?alt=media&token=02ecd3be-dbf7-42e8-ba89-125a8aa54b6f',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FSieraad%2FIMG_4371.JPG?alt=media&token=61c205f9-f631-4619-a607-94f6f01c77c0']
    }, {
        name: "Paard",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FPaard%2FPaard%20(1).jpg?alt=media&token=8e808d5a-1f1e-4dd5-88d9-04ddf1d919e1',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FPaard%2FPaard%20(2).jpg?alt=media&token=9d120e92-7bb8-4d88-b997-08819f770fa8',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FPaard%2FPaard%20(3).jpg?alt=media&token=323b2a11-bffb-4b4b-92eb-7ea73d3dc5a1',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FPaard%2FPaard.jpg?alt=media&token=a7972e4c-59f6-4b66-91c8-48b6f652093f']
    }, {
        name: "Olifant",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FOlifant%2FOlifant%20(1).jpg?alt=media&token=f9bf2f32-68e0-40d8-bd2d-439d32ea7011',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FOlifant%2FOlifant%20(2).jpg?alt=media&token=dcf04ba2-8bf3-4bbd-9053-0248a669a4a8',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FOlifant%2FOlifant%20(3).jpg?alt=media&token=c2b65db3-d55f-4798-9f54-f679a0c760b4',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FOlifant%2FOlifant%20(4).jpg?alt=media&token=d0f8465f-d791-4821-9998-f72735cdd403',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FOlifant%2FOlifant%20(5).jpg?alt=media&token=c5f895b2-0126-4de9-95a0-f537e1ec0601',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FOlifant%2FOlifant.jpg?alt=media&token=b8e0df48-7093-4de1-8fce-8d9429873026',]
    }, {
        name: "Kruk met hondenkop",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKruk%20met%20hondenkop%2FIMG_6353.JPG?alt=media&token=8409d86a-7af0-451a-990c-9a9c0e8275c3',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKruk%20met%20hondenkop%2FIMG_6356.JPG?alt=media&token=7b50715a-780a-46b4-9014-277de99d4bfb',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKruk%20met%20hondenkop%2FIMG_6363.JPG?alt=media&token=f5d535be-e31b-4afb-a285-382b4869f95a',]
    }, {
        name: "Kroon",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKroon%2FKroon%20(1).jpg?alt=media&token=8adf2345-a955-4bed-befe-3c9c900c90d6',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKroon%2FKroon%20(2).jpg?alt=media&token=0af8da6d-3115-420e-b449-d667205dde24',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKroon%2FKroon%20(3).jpg?alt=media&token=fba0911b-2193-4a0b-af4e-33e78686a39c',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKroon%2FKroon.jpg?alt=media&token=3f42c50b-6a1d-422d-9970-bdcc98519d02']
    }, {
        name: "Kikker",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKikker%2FKikker.jpg?alt=media&token=52e6a487-2634-4198-aa9d-fcf8d600775e',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKikker%2FKikker%20(2).jpg?alt=media&token=03646317-6370-4dd0-a8ac-ea39b59a329e',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKikker%2FKikker%20(1).jpg?alt=media&token=5bd55c80-4bb8-4665-bd4c-cc396a342272',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKikker%2FDSC_0748.jpg?alt=media&token=79c0c56f-23cc-4a6a-8176-6578f672ab05',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKikker%2FDSC_0747.jpg?alt=media&token=039fb91c-17ac-454e-b293-1a05974fc5e0',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKikker%2FDSC_0746.jpg?alt=media&token=d3ca40e2-b062-4664-91a9-4f4d1e53795a',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKikker%2FDSC_0745.jpg?alt=media&token=0f98bfa4-e30d-4f04-ba25-78d71ead89e7',]
    }, {
        name: "Gekkekoe",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FGekkekoe%2FGekkeKoe%20(1).jpg?alt=media&token=d78e39e5-c082-4d17-b7ec-1da7c088cab3',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FGekkekoe%2FGekkeKoe%20(2).jpg?alt=media&token=3a05bddb-dd15-4812-ad83-56f4ced85f8c',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FGekkekoe%2FGekkeKoe.jpg?alt=media&token=9c071503-320c-43cb-ac1a-757b791863b3',]
    }, {
        name: "Vrouwen torso",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVrouwen%20torso%2FIMG_5793.JPG?alt=media&token=d70e60e7-93e6-4a53-8336-fc5e81bfd4ac',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVrouwen%20torso%2FIMG_5791.JPG?alt=media&token=6c003016-b630-46b8-b95c-5be384d3c512',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVrouwen%20torso%2FIMG_5790.JPG?alt=media&token=27d7d538-88e6-4baa-a115-3520ea4688fc',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVrouwen%20torso%2FContact.JPG?alt=media&token=3903c213-38c1-484c-a28c-c38983c0b454',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVrouwen%20torso%2FIMG_5792.JPG?alt=media&token=b5f1fea6-9052-4677-8ef4-8cdecaced047']
    }, {
        name: "Bizon kit",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FBizon%20kit%2FBizon%20kit%20(1).jpg?alt=media&token=c3edd4c9-254f-4729-9267-52ab9a38d474',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FBizon%20kit%2FBizon%20kit%20(2).jpg?alt=media&token=bca14a8d-692a-4c0b-8855-da13608de7be',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FBizon%20kit%2FBizon%20kit.jpg?alt=media&token=574c1369-af59-49dc-92fa-bb1245c02b63',]
    }, {
        name: "Gedraaide kruk",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FGedraaide%20kruk%2FGedraaide%20kruk%20(1).jpg?alt=media&token=4b6709b3-007c-47b5-8b07-203221a2450e',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FGedraaide%20kruk%2FGedraaide%20kruk%20(2).jpg?alt=media&token=da73768a-c43f-4004-9278-a6599c4738f5',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FGedraaide%20kruk%2FGedraaide%20kruk%20(3).jpg?alt=media&token=4b0d9d84-3876-44a0-9c05-8fbb8566acad',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FGedraaide%20kruk%2FGedraaide%20kruk%20(4).jpg?alt=media&token=7e442839-fe93-41bc-b6ff-2d9558355103',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FGedraaide%20kruk%2FGedraaide%20kruk%20(5).jpg?alt=media&token=d68e9b09-6bac-457d-92e4-5ac81780875e',

            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FGedraaide%20kruk%2FGedraaide%20kruk.jpg?alt=media&token=293433b7-484f-4f8c-bd02-82c6a3be1329']
    }, {
        name: "Feniks",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FFeniks%2FFeniks%20(1).jpg?alt=media&token=4c098241-51a6-4fa3-826d-473438c137e8',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FFeniks%2FFeniks%20(2).jpg?alt=media&token=2f7c4aeb-3e75-45c7-910e-7c5bc395cf94',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FFeniks%2FFeniks%20(3).jpg?alt=media&token=72bc4755-fa9c-4ad4-a704-26da91d7bd81',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FFeniks%2FFeniks%20(4).jpg?alt=media&token=511ecf26-9323-4a7f-8b3a-78edd92236db',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FFeniks%2FFeniks.jpg?alt=media&token=c67c9272-52b6-49ce-bd62-5fa9f2e5c40b']
    }, {
        name: "Kiwi",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKiwi%2FKiwi%20(9).jpg?alt=media&token=9254a66a-2ed7-4cbe-ae23-2865c0beece2',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKiwi%2FKiwi%20(8).jpg?alt=media&token=c3d95ac6-c5bd-4dcd-9c26-117ae9f5e5a8',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKiwi%2FKiwi%20(7).jpg?alt=media&token=2798da8d-f770-436e-a5fa-e076219f9897',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKiwi%2FKiwi%20(6).jpg?alt=media&token=bf107c44-8d9b-4657-a96e-adba7199a465',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKiwi%2FKiwi%20(5).jpg?alt=media&token=f5301317-1323-42de-80e7-8adcd2500e50',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKiwi%2FKiwi%20(1).jpg?alt=media&token=c9784fa3-90fa-4861-a812-c449bb13bb77',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKiwi%2FKiwi%20(3).jpg?alt=media&token=1f204aca-dbe7-4e37-bb87-35e56bdf4cab',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKiwi%2FKiwi%20(4).jpg?alt=media&token=a1019655-3a29-4eac-9f38-9a9255712b27',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKiwi%2FKiwi%20(2).jpg?alt=media&token=8eb373ea-834b-4377-b6a6-69f7981bae4e',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKiwi%2FKiwi.jpg?alt=media&token=6a52bd30-d440-42a6-b682-c9ca8dfd1a71']
    }, {
        name: "Dodo",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FDodo%20(2).jpg?alt=media&token=4d2d0d78-5e41-4395-9b9d-7fc65d795e3f',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FDodo%20(3).jpg?alt=media&token=53770bca-4a9b-4d81-b9f3-bb91ccb8fcf8',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FDodo%20(4).jpg?alt=media&token=553f8cb0-deb7-4c43-8ba3-c2042c811646',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FDodo%20(5).jpg?alt=media&token=e6c7fe8d-a744-4df2-8be4-c20ed8c3ccf1',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FDodo%20(6).jpg?alt=media&token=9f31c7a3-d789-4241-9c29-10ab033476ec',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FDodo%20(7).jpg?alt=media&token=56fde1ce-03e3-4b12-b930-6f83c67d084a',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FDodo%20(8).jpg?alt=media&token=3b2e579b-2f2d-4f08-859a-e971dd431c19',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FDodo%20(9).jpg?alt=media&token=a605d606-7ab4-446e-84a4-55594fd5c60b',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FDodo%20(1).jpg?alt=media&token=1f1839d8-32a3-4795-a11b-d2b290d7bf84',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FDodo.jpg?alt=media&token=86e78344-612f-4245-b343-55a1a161dd0c',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FIMG_5798.JPG?alt=media&token=c58cbf6b-80d3-43ea-9349-147465d84808',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FIMG_5800.JPG?alt=media&token=310fbaa5-f384-4881-94df-67d23fc31345',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FIMG_5802.JPG?alt=media&token=43e898ae-7009-456d-a7ce-137d42c00c34',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FIMG_5803.JPG?alt=media&token=ed6a4575-0e7c-4512-93c4-1ffaff99222d',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FIMG_5804.JPG?alt=media&token=38d454a0-38ae-4736-925c-3093ebf1bae8',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FDodo.jpg?alt=media&token=86e78344-612f-4245-b343-55a1a161dd0c']
    }, {
        name: "Boomkikker",
        urls: ['https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FBoomkikker%2FBoomkikker%20(1).jpg?alt=media&token=21fb6f01-81a1-4fe5-a725-a9cccfa013e8',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FBoomkikker%2FBoomkikker%20(2).jpg?alt=media&token=63f04a42-6315-429e-8098-3a5e727b3f99',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FBoomkikker%2FBoomkikker%20(3).jpg?alt=media&token=6e554d6a-74b9-440e-8153-4cacb633ade2',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FBoomkikker%2FBoomkikker%20(4).jpg?alt=media&token=04f473e9-56c0-4130-ae1d-a8fae11b0f1d',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FBoomkikker%2FBoomkikker%20(5).jpg?alt=media&token=8aadae12-0827-4cee-ba57-8838f153c484',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FBoomkikker%2FBoomkikker%20(6).jpg?alt=media&token=28a67b11-913f-4e3b-a4b7-8f2fbe1b829a',
            'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FBoomkikker%2FBoomkikker.jpg?alt=media&token=9dacfa7b-7fc0-4dd0-8b27-c0e36f2ad05d']
    }
    ]


    let artworkShowMarkup = artworkShow
        .map((thing) => {
            if (thing.name === title) {
                return thing.urls.map((url, i) => <ImageCard url={url} title={title} key={i}/>)
            }
        })

    return (
        <div style={containerGallery}>
            <h1>{title}</h1>
            <Grid container spacing={2}>
                {artworkShowMarkup}
            </Grid>
        </div>
    );
}


export default ArtworkShow