import React, {FC, useEffect, useState} from 'react';

import ArtworkCard from '../component/ArtworkCard'
import Grid from "@material-ui/core/Grid";
import InfiniteScroll from "../component/InfiniteScroll";
import firebase, {auth} from "../config/firebase";
import Like from "../interfaces/Like";

export interface IGalleryProps {
}

const Gallery: FC<IGalleryProps> = props => {
// export default function Gallery() {
    const [art, setArt] = useState<Artwork[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const NUMBERS_PER_PAGE = 6;
    const TOTAL_LENGTH = result.length

    // const example = async () => {
    //     const userQuery = await firebase.firestore().collection("likes").where('email', '==', auth.currentUser?.email).get()
    //     const likes = userQuery.docs.map(x => x.data() as Like)
    //     likes.map(like => console.log(like.email))
    // }

    let recentArtworkMarkUp = art.map(artwork => (<ArtworkCard artwork={artwork} key={artwork.artId}/>))
    const hasMoreData = recentArtworkMarkUp.length < TOTAL_LENGTH

    const loadMoreNumbers = () => {
        setPage((page) => page + 1);
        setLoading(true);
        let getNumbers: number
        if ((art.length + NUMBERS_PER_PAGE) < TOTAL_LENGTH) {
            getNumbers = NUMBERS_PER_PAGE
        } else {
            getNumbers = TOTAL_LENGTH - art.length
        }
        // debugger
        const index = page * NUMBERS_PER_PAGE;
        const newArt = result.slice(index, index + getNumbers)
        // const newArt = new Array(getNumbers)
        //     .fill(1)
        //     .map((_, i) => result[page * NUMBERS_PER_PAGE + i]);
        setArt((oldArt) => [...oldArt, ...newArt])
        setLoading(false);
    }

    return (
        <InfiniteScroll
            hasMoreData={hasMoreData}
            isLoading={loading}
            onBottomHit={loadMoreNumbers}
            loadOnMount={true}
        >
            <Grid container spacing={2}>
                {recentArtworkMarkUp}
            </Grid>
        </InfiniteScroll>
    );
}

export default Gallery;

interface Artwork {
    artId: string,
    imgCount?: number
    title: string,
    username?: string
    createdAt: string,
    likeCount?: number,
    description: string,
    commentCount?: number,
    url: string,
}

// const boolR = true
const result = [{
    artId: '1',
    imgCount: 4,
    title: 'Meerkoet',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'De meerkoet',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FMeerkoet%2FIMG_5331.JPG?alt=media&token=3ff22b8e-ef50-45b0-bb69-716405655e9f'
}, {
    artId: '2',
    imgCount: 0,
    title: 'Arend',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Deze komt net aanvliegen om op dit stuk hout neer te strijken',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FArend%2FArend.jpg?alt=media&token=c7c6f3b6-cf4f-4e04-9947-77dfea6a6fc3'
}, {
    artId: '3',
    imgCount: 0,
    title: 'Wildzwijn',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'De kop van een wildzwijn',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FWildzwijn%2FWildzwijn.jpg?alt=media&token=a0d16a2c-4ef8-4e51-a34a-328aee03e686'
}, {
    artId: '4',
    imgCount: 0,
    title: 'Varken',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'De kop van een welvarend varken',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVarken%2FVarken.jpg?alt=media&token=37cb4726-0209-4b39-8772-8c7564d70a7c'
}, {
    artId: '5',
    imgCount: 0,
    title: 'Nijlpaard',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'De kop van een Nijlpaard',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FNijlpaard%2FNijlpaard.jpg?alt=media&token=bd0036b9-ac83-4b28-a610-1dfe884d7ef9'
}, {
    artId: '6',
    imgCount: 0,
    title: 'Mond',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Deze mond steekt zijn tong naar ons uit',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FMond%2FMond.jpg?alt=media&token=5a7b35c0-5cde-4fa6-9aad-e87a56697254'
}, {
    artId: '7',
    imgCount: 0,
    title: 'Kunst',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Een waaier van kunst',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKunst%2FKunst.jpg?alt=media&token=a31c605b-e66f-4920-bd31-d4287b31a376'
}, {
    artId: '8',
    imgCount: 0,
    title: 'Hand',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Een levensechte mensenhand',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FHand%2FHand.jpg?alt=media&token=79e1562e-c9b5-4ade-8155-bf41cbf08f16'
}, {
    artId: '9',
    imgCount: 0,
    title: 'Vogels',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Verschillende vogels. Kijk verder en ontdek vele andere!',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVogels%2FVogel.jpg?alt=media&token=689b75c0-0dc6-4160-a5ae-463b42fd6ce0'
}, {
    artId: '10',
    imgCount: 0,
    title: 'Verwaande vogel',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Deze vogel is enorm verwaand. Zoals je zelf ook aan zijn kop kan terugzien.',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVerwaande%20vogel%2FVerwaande%20vogel.jpg?alt=media&token=4233478e-6362-41f7-b0df-c86996f249a4'
}, {
    artId: '11',
    imgCount: 0,
    title: 'Uil',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Verborgen in de boom zit hier een wijze uil je aan te kijken.',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FUil%2FIMG_5534.JPG?alt=media&token=f373c4fb-68ea-4381-99b2-dde1b4b22c05'
}, {
    artId: '12',
    imgCount: 0,
    title: 'Totempaal',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Een totempaal, welke figuren zie jij hier allemaal verborgen door de hele paal?',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FTotempaal%2FTotempaal.jpg?alt=media&token=e949d1ca-251c-465d-9a4c-b17fd0ff6cdd'
}, {
    artId: '13',
    imgCount: 0,
    title: 'Sieraad',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Een stukje sierraad voor in je woonkamer thuis ',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FSieraad%2FIMG_4371.JPG?alt=media&token=61c205f9-f631-4619-a607-94f6f01c77c0'
}, {
    artId: '14',
    imgCount: 0,
    title: 'Paard',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'De kop van een paard',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FPaard%2FPaard.jpg?alt=media&token=a7972e4c-59f6-4b66-91c8-48b6f652093f'
}, {
    artId: '15',
    imgCount: 0,
    title: 'Olifant',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'uit deze boom komt een echte olifant gesjokt tevoorschrijn',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FOlifant%2FOlifant.jpg?alt=media&token=b8e0df48-7093-4de1-8fce-8d9429873026'
}, {
    artId: '16',
    imgCount: 0,
    title: 'Kruk met hondenkop',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Deze kruk kan je gebruiken om op te zitten. En dan heb je tussen je bennen een hondenkop liggen.',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKruk%20met%20hondenkop%2FIMG_6363.JPG?alt=media&token=f5d535be-e31b-4afb-a285-382b4869f95a'
}, {
    artId: '17',
    imgCount: 0,
    title: 'Kroon',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Deze kroon is bedoelt voor de enige echte Koning(in)',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKroon%2FKroon.jpg?alt=media&token=3f42c50b-6a1d-422d-9970-bdcc98519d02'
}, {
    artId: '18',
    imgCount: 0,
    title: 'Kikker',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Deze kikker hopt vrolijk in het rond',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKikker%2FDSC_0745.jpg?alt=media&token=0f98bfa4-e30d-4f04-ba25-78d71ead89e7'
}, {
    artId: '19',
    imgCount: 0,
    title: 'Gekkekoe',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Deze koe is niet helemaal normaal, hij is een beetje gek!',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FGekkekoe%2FGekkeKoe.jpg?alt=media&token=9c071503-320c-43cb-ac1a-757b791863b3'
}, {
    artId: '20',
    imgCount: 0,
    title: 'Vrouwen torso',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Het lichaam van een vrouw',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FVrouwen%20torso%2FIMG_5792.JPG?alt=media&token=b5f1fea6-9052-4677-8ef4-8cdecaced047'
}, {
    artId: '21',
    imgCount: 0,
    title: 'Bizon kit',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'De bizon kit',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FBizon%20kit%2FBizon%20kit.jpg?alt=media&token=574c1369-af59-49dc-92fa-bb1245c02b63'
}, {
    artId: '22',
    imgCount: 0,
    title: 'Gedraaide kruk',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'Alsof de houten kruk verbogen is',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FGedraaide%20kruk%2FGedraaide%20kruk.jpg?alt=media&token=293433b7-484f-4f8c-bd02-82c6a3be1329'
}, {
    artId: '23',
    imgCount: 0,
    title: 'Feniks',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'De Feniks (Phoenix)',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FFeniks%2FFeniks.jpg?alt=media&token=c67c9272-52b6-49ce-bd62-5fa9f2e5c40b'
}, {
    artId: '24',
    imgCount: 0,
    title: 'Kiwi',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'De kiwi\'s of snipstruisen zijn loopvogels die van nature alleen voorkomen in Nieuw-Zeeland.',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FKiwi%2FKiwi.jpg?alt=media&token=6a52bd30-d440-42a6-b682-c9ca8dfd1a71'
}, {
    artId: '25',
    imgCount: 0,
    title: 'Dodo',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'De uitgestorven dodo herrezen uit deze boom',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FDodo%2FDodo.jpg?alt=media&token=86e78344-612f-4245-b343-55a1a161dd0c'
}, {
    artId: '26',
    imgCount: 0,
    title: 'Boomkikker',
    username: 'user',
    createdAt: '2019-03-25T10:59:52.798Z',
    likeCount: 0,
    description: 'De boomkikker',
    commentCount: 0,
    url: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FBoomkikker%2FBoomkikker.jpg?alt=media&token=9dacfa7b-7fc0-4dd0-8b27-c0e36f2ad05d'
}
]


