import React, {Fragment} from 'react';
//MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//icons
//Redux
// import MyButton from "../util/MyButton";
import CSS from "csstype";


const image2: CSS.Properties = {
    margin: '0px auto',
    maxWidth: "600px",
    objectFit: 'cover',
    height: "400px",
    display: 'block',
};
const mailto: CSS.Properties = {
    display: 'flex',
    justifyContent: 'center'
};


export default function About() {
    return (
        <Grid container spacing={2}>
            <Grid item sm={12} md={3}><img src={aboutPayload.img1} alt="Eric Derksn" style={image2}/> </Grid>
            <Grid item sm={12} md={6}><Fragment>
                <Typography variant="h5" color="textPrimary">Over</Typography>
                <Typography variant="body1" color="textPrimary"> {aboutPayload.history}</Typography>
                <br/>
                <br/>
            </Fragment></Grid>
            <Grid item sm={12} md={3}><img src={aboutPayload.img2} alt="Eric Derksen 2" style={image2}/></Grid>
        </Grid>
    );
}

const aboutPayload = {
    connect: 'Voor vragen over kunstwerken of eventueel bezichteging of het aangaan tot het kopen van een kunstwerk kunt u mailen naar:',
    info: '',
    img1: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FHome%2FEricDerksen.jpg?alt=media&token=0fb0f46c-9780-4347-a5c8-93d494141bd2',
    history: 'Door de bomen zien we het bos niet meer maar wel de beelden die eruit ontstaan na een bewerking met de kettingzaag. Het is een vorm van beeldspraak, soms letterlijk naar de natuur maar ook vanuit de fantasie. Van chirurgisch handwerk naar macroscopisch kettingzaagwerk, een sprong naar een volgende dimensie. De finesse soms aangebracht met beitel en guts. Een brede keus in boomsoorten, eiken, essen, acacia, robinia, verzameld met hulp van boswachters, tuinmannen en bosrooibedrijven en geholpen door de najaarsstormen. Foto\'s hiervan vindt u hier. De objecten worden gemaakt in eigen atelier en op locatie, naar eigen inzicht maar ook op bestelling.',
    img2: 'https://firebasestorage.googleapis.com/v0/b/reacthoutzaagkunst1.appspot.com/o/images%2FHome%2FWelkom.jpg?alt=media&token=ffdff957-561e-47da-8809-5ecddeccb204'
}